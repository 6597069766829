// Use import.meta.env if available (build time), otherwise fallback to process.env (runtime for SSR app)
// These variables help us tag the Sentry traces correctly

// DSN - address where we send Sentry logs to (browser/client)
// Release Mode - local development (no sourcemaps) or production/staging deployment (we upload sourcemaps)
// Production - staging or production deployment

const isBuildTime = typeof import.meta !== "undefined" && import.meta.env;
const envContainer = isBuildTime ? import.meta.env : process.env;

// different sentry projects (and hence different DSNs) for SSR and web
const sentryDsnWeb = envContainer.PUBLIC_SENTRY_DSN_WEB;
const sentryDsnSsr = envContainer.PUBLIC_SENTRY_DSN_SSR;

// determines if we need to upload the sourcemaps to Sentry.
const isReleaseMode = isBuildTime ? import.meta.env.PROD === true : process.env.IS_RELEASE_MODE === "true";
// production vs staging mode
const isProduction = envContainer.PUBLIC_DATABASE_NAME === "(default)";

let sentryEnvironment = "development";
let sentryRelease = "local";
if (isReleaseMode) {
  if (isProduction) {
    sentryEnvironment = "production";
  } else {
    sentryEnvironment = "staging";
  }
  sentryRelease = `${envContainer.PUBLIC_VERSION}-${sentryEnvironment}`;
}

export { sentryDsnWeb, sentryDsnSsr, sentryEnvironment, sentryRelease };
