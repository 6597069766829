import * as Sentry from "@sentry/astro";
import { sentryDsnWeb, sentryEnvironment, sentryRelease } from "sentryUtils";

if (!sentryDsnWeb || !sentryEnvironment || !sentryRelease) {
  console.error("Sentry is not configured properly");
}

Sentry.init({
  dsn: sentryDsnWeb,
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: ["/^/(?!/)/", import.meta.env.PUBLIC_BASE_URL, "127.0.0.1", "localhost"],
  tracesSampleRate: 1.0,
  environment: sentryEnvironment,
  release: sentryRelease,
});
